
// Content styles
.block-text {
	line-height: 1.8;
	
	.content {

		// Default paragraphs
		p {
			margin: 0 0 $paragraph-margin;

			&.intro {
				border-top: $border;
				padding: $paragraph-margin 0;
				border-bottom: $border;
				color: $color-sub;
				font-family: $font-family-sub;
				font-size: $h4;
				font-weight: $font-light;
				
				@media #{$mobileXL} {
					font-size: $h3;
				}
			}
		}
		
		// New default <ul> lists
		ul {
			@extend %default-ul;
		}
		
		// New default <ol> lists
		ol {
			@extend %default-ol;
		}
	}
}

// CMS template issues again, yay :D
// setting [Advert|No Title] on the Lead Article doesn't add our .block-text class
// so, we need to extend and hack around to add it in ourselves :/
.block-intro {
	margin: 0 0 $paragraph-margin;
	@extend %clear-fix;

	&:empty {
		@extend .hidden;
		margin: 0;
	}

	.block-header-small {

		.title {
			margin: 0 0 ($heading-margin * 0.5);
		}
	}

	.block-sub-intro {
		@extend %sub-intro;
	}

	+ .block:not(.block-testimonials) {
		@extend .block-text;
	}
}

